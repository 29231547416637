import React from "react"
import { graphql, Link } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/layout"
import HeroHeader from '../components/heroHeader'
import PostNav from "../components/postNav"
import PostsGrid from "../components/postsGrid"

// import WpEditButton from "../components/WpEditButton"
import { processHtml } from "../utils/processHtml"

import * as style from "./single.module.css"
import ShareButtons from "../components/shareButtons"


const PostTemplate = ({ data }) => {

  const post = data.thePost
  const relatedPosts = post.relatedPosts;

  // Yoast sets featured image as opengraphImage, which is not what GG wants (cos out hero image is really our featured image - 
  // basically we're using featuredImage wrong..)
  // ..So just use the heroImage for og:Image. Means GG can't override it in Yoast (but would be odd anyway to have diff images
  // for hero and on FB.)
  // const ogImageUrl = post.seo.opengraphImage.localFile.publicURL || post.gatsbyCustomFields.heroImage?.localFile.publicURL;
  const ogImageUrl = post.gatsbyCustomFields.heroImage?.mediaItemUrl;

  // console.log('ogImage choices: ', post.seo.opengraphImage.localFile.publicURL, post.gatsbyCustomFields.heroImage?.localFile.publicURL)

  const reactPage = processHtml(post.content, post.title, post.uri)

  return (
    <Layout>

      {/* // Button to take us straight to WP editor of this post, for convenience while we get the site set up */}
      {/* <WpEditButton databaseId={post.databaseId} uri={post.uri} /> */}

      <Seo
        title={post.title}
        seo={post.seo}
        ogImageUrl={ogImageUrl}
      />

      <HeroHeader
        post={post}
      />

      <article className={`content-container ${style.article}`}>

        <ShareButtons
          url={`https://ecofeministmama.com${post.uri}`}
          pinImage={post.featuredImage?.node.link}
        />

        {/* <div className={style.article__content}
          dangerouslySetInnerHTML={{ __html: post.content }}
        /> */}

        <div className={style.article__content}>
          {reactPage}
        </div>

        <div className={style.tagBox}>
          <p>Tagged:{" "}</p>
          <div className={style.tagList}>
            {post.tags.nodes.map((tag, index) => (
              <Link key={index} to={tag.link}>
                <div className={style.tag}>{tag.name}</div>
              </Link>
            ))}
          </div>
        </div>

      </article>

      <section className='main-container'>
        <h2>You might also like...</h2>
        <PostsGrid
          // tags={post.tags.nodes}
          posts={relatedPosts}
        />

        <PostNav prevPost={data.prevPost} nextPost={data.nextPost} />
      </section>


    </Layout>
  )
}

/*
other SEO stuff available (but I think not set by GG):

    focuskw
    metaKeywords
    metaRobotsNoindex
    metaRobotsNofollow
    opengraphTitle
    opengraphDescription
    opengraphImage {
        altText
        sourceUrl
        srcSet
    }
    twitterTitle
    twitterDescription
    twitterImage {
        altText
        sourceUrl
        srcSet
    }
    canonical
    cornerstone
    schema {
        articleType
        pageType
        raw
    }

*/

export const query = graphql`
fragment seoData on WpPost {
  seo {
    title
    metaDesc
    opengraphImage {
      localFile {
        publicURL
      }
    }
  }
}
    query($databaseId: Int!, $nextId: Int, $prevId: Int) {
      thePost: wpPost(databaseId: { eq: $databaseId }) {
        date
        databaseId
        uri #??? only for link to old version!
        content
        title
        featuredImage {
          node {
            link
          }
        }
        author {
          node {
            name
          }
        }
        categories {
          nodes {
            link
            name
          }
        }
        tags {
          nodes {
            link
            name
          }
        }
        relatedPosts(limit: 6) {
          title
          uri
          date
          excerpt
          author {
            node {
              name
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 330, quality: 70) {   # ??? i should make this DRY
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        gatsbyCustomFields {
          heroImage {
            altText
            mediaItemUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 2500, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          heroImageSubjectHorizontalPos
          titleAsTwoParts
        }
        ...seoData
# ??? can delete featuredImage? ooh no think I need to show it but hide it?
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      nextPost: wpPost(databaseId: { eq: $nextId }) {
        title
        uri
      }
      prevPost: wpPost(databaseId: { eq: $prevId }) {
        title
        uri
      }
    }
`

export default PostTemplate