import React from 'react'

import {
    FacebookShareButton,
    PinterestShareButton,
    TwitterShareButton,
} from "react-share";


import styled from '@emotion/styled'

const SharesContainer = styled.div`
    margin: 0 0 1rem 0;
    font-family: var(--font-sans);
    &>*{
        margin-right: 15px;
    }
`

const ShareButton = styled.div(({ svg, x = 50, y = 50, color = 'purple' }) => (`
    width: 38px;
    height: 38px;
    background-image: url('/image/watercolour-wash-${color}.jpg');
    background-position: ${x}% ${y}%;
    mask-image: url(${svg});
    mask-repeat: no-repeat;
    opacity: 85%;
    transition: all 0.2s ease-in;
    &:hover{
        opacity: 100%;
    }
    `))


const ShareButtons = ({ url, pinImage }) => {

    if (!pinImage) console.log('No pinImage url provided to ShareButtons cpt')

    return (
        <SharesContainer>

            <FacebookShareButton
                url={url}
            >
                <ShareButton svg='/image/facebook.svg' x={50} y={14} />
            </FacebookShareButton>

            <TwitterShareButton
                url={url}
            >
                <ShareButton svg='/image/twitter.svg' x={20} y={10} />
            </TwitterShareButton>

            {pinImage &&
                <PinterestShareButton
                    url={url}
                    media={pinImage}
                >
                    <ShareButton svg='/image/pinterest.svg' x={0} y={10} />
                </PinterestShareButton>
            }

        </SharesContainer >
    )
}


export default ShareButtons